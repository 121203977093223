.App {
  text-align: center;
}
body{
  background:
}
.banner-title{
  padding-top:50px;
  padding-bottom:50px;
  font-size:38px;
  text-align:center;
  padding-top:50px;
  background-color:#F37B21;
  margin-bottom:20px;
  border-radius: 3px;
}
textarea{
  border:1px solid #F37B21 !important;
}
.border{
  border-radius:10px !important;
  border:1px solid #F37B21 !important;
}

.mr-1{
  margin-right:5px;
}
.mb-1{
  margin-bottom:5px;
}